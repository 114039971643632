<template>
    <span :ref="this.name"></span>
</template>

<script>
    import { CountUp } from 'countup.js'

    export default {
        props: {
            value: {
                type : Number,
                required : true
            },
            decimal: {
                type: Number,
                default: 0
            },
            prefix: {
                type: String,
                default: ''
            },
            suffix: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
                default()
                {
                    return {
                        decimalPlaces: this.decimal,
                        prefix: this.prefix,
                        suffix: this.suffix
                    }
                }
            }
        },
        data()
        {
            return {
                name : 'CountUp' + parseInt(Math.random() * 1000000),
                countup : null
            }
        },
        mounted()
        {
            this.countup = new CountUp(this.$refs[this.name], this.value, this.options)
            this.countup.start()
        },
        watch: {
            value(val)
            {
                this.countup.update(val)
            }
        }
    }
</script>