<template>
    <main>
        <ButtonGroup shape="circle">
            <Button icon="md-refresh" @click="loadData">刷新</Button>
        </ButtonGroup>

        <Divider />
        <Row :gutter="30">
            <Col :span="8">
                <iconcard icon-type="ios-people" bg-class="bg-primary">
                    <div slot="content" class="text-center">
                        <countup :value="count ? count.users : 0" class="text-primary text-xxl text-bold"></countup>
                        <p class="text-sm text-grey margin-top-xs">注册用户</p>
                    </div>
                </iconcard>
            </Col>

			<Col :span="8">
                <iconcard icon-type="ios-jet" bg-class="bg-primary">
                    <div slot="content" class="text-center">
                        <countup :value="count ? count.cards : 0" class="text-primary text-xxl text-bold"></countup>
                        <p class="text-sm text-grey margin-top-xs">激活数量</p>
                    </div>
                </iconcard>
            </Col>
        </Row>

        <Card :bordered="false" class="mt-xxl">
            <p slot="title">新增用户</p>
            <chart :options="charts.user" autoresize theme="macarons" style="width:100%"/>
        </Card>

		<Card :bordered="false" class="mt-xxl">
            <p slot="title">日活</p>
            <chart :options="charts.dailyActive" autoresize theme="macarons" style="width:100%"/>
        </Card>
		<Card :bordered="false" class="mt-xxl">
            <p slot="title">答题次数</p>
            <chart :options="charts.questionTimes" autoresize theme="macarons" style="width:100%"/>
        </Card>

		<Card :bordered="false" class="mt-xxl">
            <p slot="title">答题人数</p>
            <chart :options="charts.questionUsers" autoresize theme="macarons" style="width:100%"/>
        </Card>
    </main>
</template>

<script>
    import Iconcard from '@/components/Iconcard'
	import Countup from '@/components/Countup'
    import ECharts from 'vue-echarts'
	import 'echarts/theme/macarons'
	import 'echarts/lib/component/legend'
	import 'echarts/lib/component/tooltip'
	import 'echarts/lib/chart/line'

    export default {
        components: {
			Iconcard,
			Countup,
            chart: ECharts
		},
        data()
        {
            return {
                count: null,
                charts: {
					user: {
						tooltip : {
							trigger: 'axis'
						},
						legend: {
							data:[ '本月', '上月' ]
						},
						xAxis : [
							{
								type : 'category',
								boundaryGap : false,
								data : []
							}
						],
						yAxis : [
							{
								type : 'value'
							}
						],
						series : [
							{
								name: '本月',
								type: 'line',
								data: []
							},
							{
								name: '上月',
								type: 'line',
								data: []
							}
						]
					},
					dailyActive: {
						tooltip : {
							trigger: 'axis'
						},
						legend: {
							data:[ '本月', '上月' ]
						},
						xAxis : [
							{
								type : 'category',
								boundaryGap : false,
								data : []
							}
						],
						yAxis : [
							{
								type : 'value'
							}
						],
						series : [
							{
								name: '本月',
								type: 'line',
								data: []
							},
							{
								name: '上月',
								type: 'line',
								data: []
							}
						]
					},
					questionTimes: {
						tooltip : {
							trigger: 'axis'
						},
						legend: {
							data:[ '本月', '上月' ]
						},
						xAxis : [
							{
								type : 'category',
								boundaryGap : false,
								data : []
							}
						],
						yAxis : [
							{
								type : 'value'
							}
						],
						series : [
							{
								name: '本月',
								type: 'line',
								data: []
							},
							{
								name: '上月',
								type: 'line',
								data: []
							}
						]
					},
					questionUsers: {
						tooltip : {
							trigger: 'axis'
						},
						legend: {
							data:[ '本月', '上月' ]
						},
						xAxis : [
							{
								type : 'category',
								boundaryGap : false,
								data : []
							}
						],
						yAxis : [
							{
								type : 'value'
							}
						],
						series : [
							{
								name: '本月',
								type: 'line',
								data: []
							},
							{
								name: '上月',
								type: 'line',
								data: []
							}
						]
					}
				}
            }
        },
        mounted()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                this.loadStats()
                this.loadUsers()
				this.loadDailyActive()
				this.loadQuestionTimes()
				this.loadQuestionUsers()
            },
            loadStats()
            {
                this.$http.get('/stats')
					.then(res => {
						this.count = res
					})
            },
            loadUsers()
            {
                this.$http.get('/stats/userChart')
					.then(res => {
						this.charts.user.xAxis[0].data  = res.legend
                        this.charts.user.series[0].data = res.this_month
                        this.charts.user.series[1].data = res.last_month
					})
            },
            loadDailyActive()
            {
                this.$http.get('/stats/dailyActiveChart')
					.then(res => {
						this.charts.dailyActive.xAxis[0].data  = res.legend
                        this.charts.dailyActive.series[0].data = res.this_month
                        this.charts.dailyActive.series[1].data = res.last_month
					})
			},
			loadQuestionTimes()
            {
                this.$http.get('/stats/questionTimesChart')
					.then(res => {
						this.charts.questionTimes.xAxis[0].data  = res.legend
                        this.charts.questionTimes.series[0].data = res.this_month
                        this.charts.questionTimes.series[1].data = res.last_month
					})
			},
			loadQuestionUsers()
            {
                this.$http.get('/stats/questionUsersChart')
					.then(res => {
						this.charts.questionUsers.xAxis[0].data  = res.legend
                        this.charts.questionUsers.series[0].data = res.this_month
                        this.charts.questionUsers.series[1].data = res.last_month
					})
            }
        }
    }
</script>