<template>
    <Card :padding="0">
        <Row style="height: 100px">
            <Col :span="8" :class="[ 'h100', bgClass, textClass ]" style="border-radius: 4px 0 0 4px">
                <Row class="h100" type="flex" align="middle" justify="center">
                    <Icon :type="iconType" :size="iconSize"></Icon>
                </Row>
            </Col>
            <Col :span="16" class="h100">
                <Row class="h100" type="flex" align="middle" justify="center">
                    <slot name="content"></slot>
                </Row>
            </Col>
        </Row>
    </Card>
</template>

<script>
    export default {
        props: {
            bgClass: {
                type: String,
                default: 'bg-primary'
            },
            textClass: {
                type: String,
                default: 'text-white'
            },
            iconType: {
                type: String,
                default: 'android-person'
            },
            iconSize: {
                type: Number,
                default: 40
            }
        }
    }
</script>